import { FC } from 'react';
import { Button, Col, Row } from 'antd';
import DataFilter from '@copilot/common/components/tables/dataFilter';
import FilterList from '@copilot/common/components/filters/list';
import styled from 'styled-components';
import { InboxMessagesFiltersProps } from '@copilot/common/pages/inbox/ui/types';
import BooleanItem from '@copilot/common/components/filters/booleanItem';

/**
 * The styled column to host filter.
 */
const StyledFilterColumn = styled(Col)`
	margin-right: 7px;
	flex: 0 1 auto;
	display: flex;
	flex-direction: column;
	max-width: 240px;
`;

/**
 * Presentation Component for Inbox Email View Filters
 * @param props
 */
const InboxPageEmailViewFilters: FC<InboxMessagesFiltersProps> = (props) => {
	const { filters, filterUpdateCallbacks, isTeamMembersFilterVisible } = props;
	const {
		tags, campaigns, teamMembers, newMessagesOnly, showSnoozedOnly, sentiment,
	} = filters;
	const {
		onCampaignFilterUpdate,
		onTeamMemberFilterUpdate,
		onFilterReset,
		onTagFilterUpdate,
		onNewMessagesOnlyUpdate,
		onShowSnoozedOnlyUpdate,
		onSentimentUpdate,
	} = filterUpdateCallbacks;
	return (
		<>
			<Row align="middle" style={{ alignItems: 'baseline', padding: '15px 0' }}>
				<StyledFilterColumn>
					<DataFilter
						title="Campaigns"
						filters={campaigns}
						updateFilters={onCampaignFilterUpdate}
					/>
					<FilterList
						filters={campaigns}
						updateFilterSelector={onCampaignFilterUpdate}
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					<DataFilter
						title="Tags"
						filters={tags}
						updateFilters={onTagFilterUpdate}
					/>
					<FilterList
						filters={tags}
						updateFilterSelector={onTagFilterUpdate}
						isExcludable
					/>
				</StyledFilterColumn>
				{isTeamMembersFilterVisible && (
					<StyledFilterColumn>
						<DataFilter
							title="Team Members"
							filters={teamMembers}
							updateFilters={onTeamMemberFilterUpdate}
						/>
						<FilterList
							filters={teamMembers}
							updateFilterSelector={onTeamMemberFilterUpdate}
						/>
					</StyledFilterColumn>
				)}
				<StyledFilterColumn>
					<DataFilter
						title="Initial Sentiment"
						filters={sentiment}
						updateFilters={onSentimentUpdate}
					/>
					<FilterList
						filters={sentiment}
						updateFilterSelector={onSentimentUpdate}
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					<Button
						onClick={() => onShowSnoozedOnlyUpdate(!showSnoozedOnly)}
						type={showSnoozedOnly ? 'primary' : 'default'}
						ghost={showSnoozedOnly}
					>
						Reminder Set
					</Button>
					<BooleanItem
						label="Reminder Set"
						isVisible={showSnoozedOnly}
						onClose={() => onShowSnoozedOnlyUpdate(false)}
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					<Button
						onClick={() => onNewMessagesOnlyUpdate(!newMessagesOnly)}
						type={newMessagesOnly ? 'primary' : 'default'}
						ghost={newMessagesOnly}
					>
						Unread
					</Button>
					<BooleanItem
						label="Unread"
						isVisible={newMessagesOnly}
						onClose={() => onNewMessagesOnlyUpdate(false)}
					/>
				</StyledFilterColumn>
			</Row>
			<Button style={{ marginBottom: '7px' }} onClick={onFilterReset}>
				Reset Filters
			</Button>
		</>
	);
};
export default InboxPageEmailViewFilters;
