import { ComponentProps, PropsWithChildren } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

const AnimatedClassName = 'animation-slide-right-then-shrink';
const StyledDiv = styled(TransitionGroup)`
	.${AnimatedClassName} {
        &-exit {
        	max-height: 500px;
            opacity:1;
        }
        &-exit-active {
			max-height: 0px;
			opacity: 0;
			transform: translateX(100%);
			transition: 800ms;
        }
    }
`;

/**
 * Applying CSS Transition to slide right then shrink on exit
 */
export const SlideRightThenShrinkAnimation = (props: ComponentProps<typeof CSSTransition>): JSX.Element => <CSSTransition classNames={AnimatedClassName} timeout={800} {...props} />;

/**
 * Applying Transition Group for elements to slide right then shrink on exit
 */
export const SlideRightThenShrinkAnimationGroup = <T,>(props: PropsWithChildren<T>): JSX.Element => (
	<StyledDiv>
		{props.children}
	</StyledDiv>
);
