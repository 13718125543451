import { ErrorsMap, Evaluators } from '@copilot/common/store/models/const/enum';
import { BitwiseUtilityFunctions } from '@copilot/common/utils/common/bitwise';
import { QueryObject } from '@copilot/data/managers/base';
import isNil from 'lodash/isNil';

/**
* creates an array of int to represent possible bit flag combination with errors selected
* @param errors errors selected
*/
const transcribeErrorBitLogic = (errors: string): string => {
	const numOfErrorBit = Object.keys(ErrorsMap).length;
	const errorFlags = errors.split(',').reduce((acc, err) => acc + (ErrorsMap[err] ?? 0), 0);
	const combinations = BitwiseUtilityFunctions.generateBitflagCombination(
		errorFlags,
		numOfErrorBit
	);
	return combinations.join('|');
};

/**
 * return an evaluator based on the value
 * '=' for 0
 * '>=' for positive values
 * '<=' for negative values
 * @param value 
 * @returns 
 */
const equalZeroValueBasedEvaluator = (value: string): string => {
	const numValue = Number.parseInt(value);
	if (numValue === 0)
		return '==';
	else if (numValue > 0)
		return '>=';
	else
		return '<=';
};

/**
 * type for filter mapping 
 */
type FilterMap = TypedObject<
	{ evaluator?: string; evaluations?: (value: any) => string; valueBasedEvaluator?: (value: any) => string; }
>;

export const FilterMapping: FilterMap = {
	Name: {
		evaluator: Evaluators.CaseInsensitiveContains,
	},
	LinkedInError: {
		evaluations: transcribeErrorBitLogic,
	},
	ActivationsRequired: {
		evaluator: Evaluators.LargerOrEqual,
	},
	TotalActiveCampaigns: {
		valueBasedEvaluator: equalZeroValueBasedEvaluator,
	},
	TotalRunningCampaigns: {
		evaluator: Evaluators.Larger,
	},
	TotalRunningNurtureCampaigns: {
		evaluator: Evaluators.LargerOrEqual,
	},
};

export class CustomerSuccessQuery extends QueryObject {
	public filterMapping: FilterMap;

	constructor(query: Partial<CustomerSuccessQuery> = {}) {
		super(query);
		this.filterMapping = query.filterMapping ?? {};
	}

	public addMappedFilter = (key: string, value: string): void => {
		const evaluatedValue = this.filterMapping[key]?.evaluations ?
			this.filterMapping[key].evaluations!(value) : value;
		if (evaluatedValue === 'all' || evaluatedValue === '-1') return;
		let evaluator = this.filterMapping[key]?.evaluator ?? '==';
		if (!isNil(this.filterMapping[key]?.valueBasedEvaluator))
			evaluator = this.filterMapping[key].valueBasedEvaluator!(value);
		this.addFilter(key, evaluator, evaluatedValue);
	};
}
