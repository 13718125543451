import {
	Col, Divider, Row, Space, Tag, Typography,
} from 'antd';

const { Title, Text } = Typography;

/**
 * Properties to display amount due summary.
 */
interface AmountDueSummaryProps {
	/**
	 * Subtotal to display in amount due summary.
	 */
	subtotal: string;
	/**
	 * Tax amount to display in amount due summary.
	 */
	tax: string;
	/**
	 * Tax rate to display in amount due summary. (ex. 13%)
	 */
	taxRate: string;
	/**
	 * Credit balance to display in amount due summary.
	 */
	creditBalance?: string;
	/**
	 * Amount due to display in amount due summary.
	 */
	amountDue: string;
}

/**
 * [Presentational] Amount due summary with subtotal, tax, tax rate, (credit balance) and amount due.
 */
const AmountDueSummary: React.FC<AmountDueSummaryProps> = ({
	subtotal, tax, taxRate, creditBalance, amountDue,
}) => (
	<>
		<Row justify="space-between">
			<Col offset={14}>
				<Text>Subtotal</Text>
			</Col>
			<Col>
				<Text>{subtotal}</Text>
			</Col>
		</Row>
		<Row justify="space-between">
			<Col offset={14}>
				<Space>
					<Text>Tax</Text>
					{ taxRate && <Tag>{taxRate}</Tag> }
				</Space>
			</Col>
			<Col>
				{ taxRate ? <Text>{tax}</Text> : <Text type="secondary">N/A</Text> }
			</Col>
		</Row>
		<Divider />
		{
			creditBalance && (
				<>
					<Row justify="space-between">
						<Col offset={14}>
							<Text>Credit Balance</Text>
						</Col>
						<Col>
							<Text>{creditBalance}</Text>
						</Col>
					</Row>
					<Divider />
				</>
			)
		}
		<Row justify="space-between">
			<Col offset={14}>
				<Title level={5}>Amount Due</Title>
			</Col>
			<Col>
				<Title level={5}>{amountDue}</Title>
			</Col>
		</Row>
	</>
);

export default AmountDueSummary;
