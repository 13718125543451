interface ConditionalWrapperProps {
	condition: boolean;
	wrapper: (children: any) => any;
}

/**
 * Conditional Wrapper component that wraps an element with a parent element conditionally.
 * See https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2 for more info.
 */
const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ children, condition, wrapper }) =>
	(condition ? wrapper(children) : children);

export default ConditionalWrapper;
