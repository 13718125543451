import { FC } from 'react';
import { Button, Col, Row } from 'antd';
import DataFilter from '@copilot/common/components/tables/dataFilter';
import FilterList from '@copilot/common/components/filters/list';
import styled from 'styled-components';
import { InboxMessagesFiltersProps } from '@copilot/common/pages/inbox/ui/types';
import { FilterVisibilityType } from './const';

/**
 * The styled column to host filter.
 */
const StyledFilterColumn = styled(Col)`
	margin-right: 7px;
	flex: 0 1 auto;
	display: flex;
	flex-direction: column;
	max-width: 240px;
`;

type InboxCardViewFiltersProps = Omit<InboxMessagesFiltersProps, 'isTeamMembersFilterVisible'> & { filterVisibility: FilterVisibilityType };

/**
 * Filters for Inbox Card View
 * @param {InboxMessageFilters} filters filters inbox messages are filtered by
 * @param {InboxMessageFiltersCallbacksType} filterUpdateCallbacks callbacks for updating the filters
 * @param {FilterVisibilityType} filterVisibility object that defines which filters are visible
 */
const InboxCardViewFilters: FC<InboxCardViewFiltersProps> = (props) => {
	const { filters, filterUpdateCallbacks, filterVisibility } = props;
	const { campaigns, teamMembers } = filters;
	const { onCampaignFilterUpdate, onTeamMemberFilterUpdate, onFilterReset } = filterUpdateCallbacks;
	return (
		<>
			<Row align="middle" style={{ alignItems: 'baseline', padding: '15px 0' }}>
				{filterVisibility.campaigns && (
					<StyledFilterColumn>
						<DataFilter
							title="Campaigns"
							filters={campaigns}
							updateFilters={onCampaignFilterUpdate}
						/>
						<FilterList
							filters={campaigns}
							updateFilterSelector={onCampaignFilterUpdate}
						/>
					</StyledFilterColumn>
				)}
				{filterVisibility.teamMembers && (
					<StyledFilterColumn>
						<DataFilter
							title="Team Members"
							filters={teamMembers}
							updateFilters={onTeamMemberFilterUpdate}
						/>
						<FilterList
							filters={teamMembers}
							updateFilterSelector={onTeamMemberFilterUpdate}
						/>
					</StyledFilterColumn>
				)}
			</Row>
			<Button style={{ marginBottom: '7px' }} onClick={onFilterReset}>
				Reset Filters
			</Button>
		</>
	);
};
export default InboxCardViewFilters;
