import { FC } from 'react';
import { MessageTemplateModel } from '@copilot/common/pages/settings/template/data/models';
import { useSelector } from 'react-redux';
import { MessageTemplateSelectors } from '@copilot/common/store/selectors/messageTemplate';

/**
 * [Smart] HOC to inject message templates as props
 * @param Component
 */
const withMessageTemplates = <T,>(Component: FC<T & { messageTemplates: MessageTemplateModel[] }>) => (props: T) => {
	const messageTemplates = useSelector(MessageTemplateSelectors.getMessageTemplates);
	return <Component messageTemplates={messageTemplates.map(m => m.ref)} {...props} />;
};

export default withMessageTemplates;
