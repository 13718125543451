import React from 'react';
import {
	Badge, Card, Typography, Button, Select,
} from 'antd';
import styled from 'styled-components';
import ConditionalWrapper from '@copilot/common/components/conditionalWrapper/conditionalWrapper';
import { SelectValue } from 'antd/lib/select';

const { Paragraph, Title } = Typography;
const { Option } = Select;

const DARK_GREEN = '#008800';
const DARK_BLUE = '#0075e2';
const LIGHT_GREEN = '#f0fae5';
const LIGHT_BLUE = '#f1f5fb';
const WHITE = '#FFFFF';

const StyledBox = styled.div`
	border-width: 2px;

	.copilot-ribbon-wrapper {
		height: 100%;
	}
`;

export interface DropdownOption {
	/**
	 * Id of the item that will be emitted on dropdown selection change.
	 */
	id: string;
	/**
	 * Name of the item that will appear in the dropdown.
	 */
	name: string;
	/**
	 * Whether the item is disabled or not
	 */
	disabled?: boolean;
}

/**
 * Properties to display item selection card
 */
interface ItemSelectionCardProps {
	/**
	 * The title of the item to display in the card.
	 */
	title: string;
	/**
	 * The primary text of the item to display in the card.
	 */
	primaryText: string;
	/**
	 * The secondary text of the item to display in the card (e.g. pricing interval)
	 */
	secondaryText: string;
	/**
	 * The dropdown options to display in the card dropdown. **NOTE: There must be at least one dropdown option. When there is only one option, no dropdown will be displayed.**
	 */
	dropdownOptions?: DropdownOption[];
	/**
	 * The selected dropdown id to show. **NOTE: Id must match an item id in dropdownOptions.**
	 */
	selectedDropdownItemId?: string;
	/**
	 * True if the select button should be hidden.
	 */
	hideSelectButton?: boolean;
	/**
	 * True if ribbon and border with background should be shown.
	 */
	showRibbonAndBorder?: boolean;
	/**
	 * The ribbon text to display if ribbon is shown.
	 */
	ribbonText?: string;
	/**
	 * The color scheme of the card to display if ribbon and border are shown.
	 */
	colorScheme?: 'green' | 'blue';
	/**
	 * Emits the item id when dropdown selection has changed.
	 */
	dropdownSelectionChanged?: (itemId: string | undefined) => void;
	/**
	 * Emits when selected is clicked.
	 */
	selectedClicked?: () => void;
}

/**
 * [Presentational] component for item selection card with dropdown
 */
const ItemSelectionCard: React.FC<ItemSelectionCardProps> = ({
	title, primaryText, secondaryText, dropdownOptions, hideSelectButton, showRibbonAndBorder = false, ribbonText, colorScheme, selectedClicked, selectedDropdownItemId, dropdownSelectionChanged,
}) => {
	if (!!selectedDropdownItemId && !dropdownOptions?.find((item: DropdownOption) => item.id === selectedDropdownItemId)) {
		throw new Error('selectedDropdownItemId must exist in dropdownOptions');
	}

	const onDropdownItemSelected = (value: SelectValue) => {
		dropdownSelectionChanged?.(value?.toString());
	};

	const showDropdown = !!dropdownOptions && dropdownOptions?.length > 1;

	return (
		<StyledBox style={{
			borderStyle: showRibbonAndBorder ? 'solid' : 'none',
			borderColor: showRibbonAndBorder ? (colorScheme === 'green' ? DARK_GREEN : DARK_BLUE) : 'none',
		}}
		>
			<ConditionalWrapper
				condition={showRibbonAndBorder}
				wrapper={(children) => (
					<Badge.Ribbon
						text={ribbonText}
						color={colorScheme === 'green' ? DARK_GREEN : DARK_BLUE}
						style={{ top: '-10px' }}
					>
						{children}
					</Badge.Ribbon>
				)}
			>
				<Card
					style={{
						backgroundColor: showRibbonAndBorder ? (colorScheme === 'green' ? LIGHT_GREEN : LIGHT_BLUE) : WHITE,
						justifyContent: 'center',
						textAlign: 'center',
						height: '100%',
					}}
					bodyStyle={{ padding: '40px 20px 48px' }}
				>
					<Title level={2} style={{ fontSize: '16px' }}>
						{title}
					</Title>
					<h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '0.1em' }}>
						{primaryText}
					</h2>
					<Paragraph style={{ fontSize: '14px' }} type="secondary">
						{secondaryText}
					</Paragraph>
					<Select
						style={{ 
							visibility: showDropdown ? 'visible' : 'hidden',
							width: '100%',
							marginBottom: '1em',
						}}
						value={selectedDropdownItemId}
						onChange={onDropdownItemSelected}
					>
						{
							dropdownOptions?.map((item: DropdownOption) => (
								<Option value={item.id} key={item.id} disabled={item.disabled}>
									{item.name}
								</Option>
							))
						}
					</Select>
					<Button
						style={{ visibility: hideSelectButton ? 'hidden' : 'visible' }}
						block
						type="primary"
						onClick={selectedClicked}
					>
						Select
					</Button>
				</Card>
			</ConditionalWrapper>
		</StyledBox>
	);
};

export default ItemSelectionCard;
