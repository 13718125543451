import { FC, ReactNodeArray } from 'react';
import { getChildByType } from '@copilot/common/utils/common';
import { InboxDisplayType } from '@copilot/data/requests/models';
import BasicContainer from '@copilot/common/components/containers/basic';
import { Col, Row } from 'antd';
import InboxViewSelector from '@copilot/common/pages/inbox/ui/component/viewSelector';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';

//#region Sub components
/**
 * [Presentational] Inbox Page's Title sub-component
 * @param props
 */
const Header: FC<{}> = (props) => <ErrorBoundary>{props.children}</ErrorBoundary>;

/**
 * [Presentational] Inbox Page's Filter sub-component
 * @param props
 */
const Filter: FC<{}> = (props) => <ErrorBoundary>{props.children}</ErrorBoundary>;

/**
 * [Presentational] Inbox Page's Content sub-component
 * @param props
 */
const Content: FC<{}> = (props) => <ErrorBoundary>{props.children}</ErrorBoundary>;

//#endregion

type InboxPageLayoutSubComponentsType = {
	Header: typeof Header;
	Filter: typeof Filter;
	Content: typeof Content;
};

type InboxPageLayoutProps = {
	/** Children */
	children: ReactNodeArray;
	/** Inbox View Type */
	viewType: InboxDisplayType;
	/** callbacks for switching the view*/
	onViewUpdate: (viewType: InboxDisplayType) => void;
};

/**
 * [Presentational] Inbox Page Layout
 * @param props
 */
const InboxPageLayout: FC<InboxPageLayoutProps> & InboxPageLayoutSubComponentsType = (props) => {
	const { viewType, onViewUpdate } = props;
	const HeaderComponent = getChildByType(props.children, Header);
	const FilterComponent = getChildByType(props.children, Filter);
	const ContentComponent = getChildByType(props.children, Content);

	return (
		<BasicContainer>
			<BasicContainer.Header>
				<Row justify="space-between" align="middle">
					<Col span={1}>
						{HeaderComponent}
					</Col>
					<Col>
						<InboxViewSelector viewType={viewType} onUpdate={onViewUpdate} />
					</Col>
				</Row>
				{FilterComponent}
			</BasicContainer.Header>
			{ContentComponent}
		</BasicContainer>
	);
};

InboxPageLayout.Header = Header;
InboxPageLayout.Filter = Filter;
InboxPageLayout.Content = Content;
export default InboxPageLayout;
