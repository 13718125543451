import { Radio } from 'antd';
import { IdcardOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { FC, useCallback } from 'react';
import { RadioChangeEventTarget } from 'antd/es/radio';
import { InboxDisplayType } from '@copilot/data/requests/models';

type InboxViewSelectorProps = {
	viewType: InboxDisplayType;
	onUpdate: (viewType: InboxDisplayType) => void;
};
const InboxViewSelector: FC<InboxViewSelectorProps> = (props) => {
	const { viewType, onUpdate } = props;
	const handleUpdate = useCallback(
		(event: { target: RadioChangeEventTarget }) => { onUpdate(event.target.value as InboxDisplayType); },
		[onUpdate]
	);
	return (
		<Radio.Group
			value={viewType}
			buttonStyle="solid"
			onChange={handleUpdate}
		>
			<Radio.Button value={InboxDisplayType.Cards}>
				<IdcardOutlined style={{ paddingRight: '5px' }} />
				Card View
			</Radio.Button>
			<Radio.Button value={InboxDisplayType.Email}>
				<UnorderedListOutlined style={{ paddingRight: '5px' }} />
				List View
			</Radio.Button>
		</Radio.Group>
	);
};
export default InboxViewSelector;
