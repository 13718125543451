import React, { useEffect } from 'react';
import {
	Alert, Col, Row, Tabs,
} from 'antd';
import NextInvoiceSummary from '@copilot/cs/src/components/billing/nextInvoiceSummary';
import RecentInvoiceSummary from '@copilot/cs/src/components/billing/recentInvoiceSummary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	 fetchBillingAction, fetchOrgMemberAction,
} from '@copilot/cs/src/pages/customerDetails/data/saga';
import { OrganizationMemberManager } from '@copilot/data';
import { getOrganizationMemberName } from '@copilot/common/utils/organizationMember';
import { AdminManager } from '@copilot/data/managers/admin';
import { IBillingInfo, InvoiceStatus } from '@copilot/data/responses/models/billing';
import CurrentSubscriptionSummary from '@copilot/cs/src/components/billing/currentSubscriptionSummary';
import { CancellingAlert, ProratedChargesAlert, UpdateScheduleAlert } from '@copilot/cs/src/components/billing/stripeAlerts';
import { LoadingPane } from '@copilot/common/components/loadingPane/loadingPane';
import { useCSCustomerBillingTracking } from './tracking';
import {
	orgMemberSelector, orgMemberOneSelector, billingSelector, billingOneSelector,
} from '../data/selectors';

/**
 * The Left Pane of the billing page.
 * Shows the current subscription and the most recent invoices
 *
 * @param props.isError is the data have an error?
 * @param props.billingInfo the billing information to show
 * @param props.orgMemberId the organization members id
 * @returns
 */
const LeftPane: React.FC<{ name: string | undefined, isError: boolean, billingInfo: IBillingInfo | undefined, orgMemberId: string }> = (props) => {
	const {
		name, isError, billingInfo, orgMemberId,
	} = props;
	return !isError ?
		(billingInfo ? (
			<>
				<CurrentSubscriptionSummary
					subscription={billingInfo.subscription}
					previousSubscription={billingInfo.previousSubscription}
					customerName={name}
					stripeCustomerId={billingInfo.id}
					orgMemberId={orgMemberId}
					wasUnpaid={billingInfo.pastInvoices[0]?.status === InvoiceStatus.open && !!billingInfo.pastInvoices[0]?.paymentStatus}
					hasProration={!!billingInfo.upcomingInvoice?.proratedAmount}
				/>
				<RecentInvoiceSummary
					invoice={billingInfo.pastInvoices[0]}
				/>
			</>
		) : null
		) : (<Alert type="error" showIcon message="Failed fetching billing information for this customer" />);
};

/**
 * The Right Pane of the billing page.
 * Shows details about the upcoming invoice and changes that are coming
 *
 * @param props.name the name of the customer
 * @param props.isError is the data have an error?
 * @param props.billingInfo the billing information to show
 * @returns
 */
const RightPane: React.FC<{ name: string | undefined, isError: boolean, billingInfo: IBillingInfo | undefined }> = (props) => {
	const { isError, billingInfo, name } = props;
	const trackEvent = useCSCustomerBillingTracking('Right Pane');
	return !isError && billingInfo ? (
		<NextInvoiceSummary
			invoice={billingInfo.upcomingInvoice}
		>
			{
				billingInfo?.subscription?.scheduleEndDate && name && (
					<UpdateScheduleAlert date={billingInfo.subscription.scheduleEndDate} name={name} subscriptionId={billingInfo.subscription.id} onClick={() => trackEvent({ buttonClicked: 'View Changes on Stripe' })} />
				)
			}
			{
				billingInfo?.subscription?.cancelAtDate && !billingInfo?.subscription?.endedDate && name && (
					<CancellingAlert date={billingInfo.subscription.cancelAtDate} name={name} subscriptionId={billingInfo.subscription.id} onClick={() => trackEvent({ buttonClicked: 'View on Stripe' })} />
				)
			}
			{
				!!billingInfo.upcomingInvoice?.proratedAmount && (
					<ProratedChargesAlert customerId={billingInfo.id} subscriptionId={billingInfo.subscription.id} onClick={() => trackEvent({ buttonClicked: 'View full invoice on Stripe' })} />
				)
			}
		</NextInvoiceSummary>
	) : null;
};

/**
 * [Smart] component for billing page
 */
const BillingPage: React.FC = () => {
	const { orgMemberId } = useParams<{ orgMemberId: string }>();

	const dispatch = useDispatch();

	const orgMember = useSelector(orgMemberSelector);
	const orgMemberOne = useSelector(orgMemberOneSelector(orgMemberId));
	const billing = useSelector(billingSelector);
	const billingInfo = useSelector(billingOneSelector(orgMemberOne?.stripeCustomerId ?? ''));

	useEffect(() => {
		dispatch(
			fetchOrgMemberAction(OrganizationMemberManager.getMember, orgMemberId)
		);
	}, [orgMemberId]);

	useEffect(() => {
		if (orgMemberOne?.stripeCustomerId) {
			dispatch(
				fetchBillingAction(AdminManager.getIndividualBillingInfo, orgMemberOne.stripeCustomerId)
			);
		}
	}, [orgMemberOne?.stripeCustomerId]);

	return (
		<Tabs>
			<Tabs.TabPane tab="Subscription Information">
				<Row gutter={[16, 16]}>
					<Col md={24} lg={14}>
						<LoadingPane isLoading={orgMember.loading || billing.loading}>
							{
								orgMemberOne?.stripeCustomerId ? <LeftPane name={orgMemberOne && getOrganizationMemberName(orgMemberOne)} isError={billing.error} billingInfo={billingInfo} orgMemberId={orgMemberId} />
									: (<Alert type="warning" showIcon message="This customer does not have an associated stripe account" />)
							}
						</LoadingPane>
					</Col>
					<Col md={24} lg={10}>
						<LoadingPane isLoading={billing.loading}>
							<RightPane name={orgMemberOne && getOrganizationMemberName(orgMemberOne)} isError={billing.error} billingInfo={billingInfo} />
						</LoadingPane>
					</Col>
				</Row>
			</Tabs.TabPane>
		</Tabs>
	);
};

export default BillingPage;
