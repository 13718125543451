import { createFetchOneSelector, createFetchSelector } from '@copilot/common/store/selectors/fetch';
import { OrganizationMemberResponse } from '@copilot/data/responses/interface';
import { IBillingInfo, IProduct, ICoupon } from '@copilot/data/responses/models/billing';
import {
	BILLING, BILLING_ORGANIZATION_MEMBER, PRODUCT, COUPON,
} from './constant';

export const billingOneSelector = createFetchOneSelector<IBillingInfo>(BILLING);
export const billingSelector = createFetchSelector(BILLING);

export const orgMemberOneSelector = createFetchOneSelector<OrganizationMemberResponse>(BILLING_ORGANIZATION_MEMBER);
export const orgMemberSelector = createFetchSelector(BILLING_ORGANIZATION_MEMBER);

export const productSelector = createFetchSelector<IProduct>(PRODUCT);

export const couponSelector = createFetchSelector<ICoupon>(COUPON);
