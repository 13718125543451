import { FC } from 'react';
import {
	Col, Button, Popconfirm, Menu, Row, Card,
} from 'antd';
import MarkAsButton from '@copilot/common/components/button/markAs';
import type { MenuInfo } from 'rc-menu/lib/interface';
import HidableBottomPanel from '@copilot/common/components/containers/hidableBottomPanel';
import BatchMigrateConnections
	from '@copilot/common/components/componentModels/activities/batchActions/actions/migrateConnections';
import {
	MigrateApplyTags,
	MigrateConnections,
} from '@copilot/common/components/componentModels/activities/batchActions/type';

type InboxPageBatchActionProps = {
	/** Messages that action applies to */
	messages: {
		threadId: string;
		connectionId: string;
		contactName: string;
		campaignId: string;
		orgMemberId: string;
	}[],
	/** callbacks for the actions */
	callbacks: {
		onDismiss: (items: { threadId: string }[]) => unknown;
		onMarkAsRead: (items: { threadId: string }[], isRead: boolean) => unknown;
		onApplyTags: (connections: { id: string, name: string }[]) => unknown;
		onMigrate: MigrateConnections;
		onMigrateTag: MigrateApplyTags;
	}
};

const InboxMessageStatus = {
	Unread: 'unread',
	Read: 'read',
};

/**
 * Presentation Component for Inbox Batch Action
 * @param props
 */
const InboxPageBatchAction: FC<InboxPageBatchActionProps> = (props) => {
	const { messages, callbacks } = props;
	const connections = messages.map((message) => ({
		id: message.connectionId,
		campaignId: message.campaignId,
		orgMemberId: message.orgMemberId,
	}));
	const {
		onDismiss, onMarkAsRead, onApplyTags, onMigrate, onMigrateTag,
	} = callbacks;
	const handleDismiss = () => {
		onDismiss(messages);
	};
	const handleMarkAs = (option: MenuInfo) => {
		const isRead = option.key === InboxMessageStatus.Read;
		onMarkAsRead(messages, isRead);
	};
	const handleApplyTags = () => {
		const connectionInfos = messages.map((row) => ({ id: row.connectionId, name: row.contactName }));
		onApplyTags(connectionInfos);
	};
	return (
		<HidableBottomPanel hidden={!messages.length}>
			<Card>
				<Row justify="space-between" align={'middle'} style={{ padding: '10px' }}>
					<Col span={19}>
						<Row align="middle" gutter={[16, 0]}>
							<Col>
								<span>Batch Actions:</span>
							</Col>
							<Col>
								<Button onClick={handleApplyTags} type="primary" ghost>
									Apply Tags
								</Button>
							</Col>
							<Col>
								<MarkAsButton>
									<Menu onClick={handleMarkAs}>
										<Menu.Item key={InboxMessageStatus.Read}>Mark As Read</Menu.Item>
										<Menu.Item key={InboxMessageStatus.Unread}>Mark As Unread</Menu.Item>
									</Menu>
								</MarkAsButton>
							</Col>
							<Col>
								<Popconfirm
									title="Are you sure you wish to dismiss the selected messages?"
									onConfirm={handleDismiss}
									okText="Yes"
									cancelText="No"
								>
									<Button type="primary" ghost>
										Dismiss
									</Button>
								</Popconfirm>
							</Col>
							<BatchMigrateConnections
								connections={connections}
								onMigrate={onMigrate}
								onMigrateTag={onMigrateTag}
							/>
						</Row>
					</Col>
					<Col span={5} style={{ textAlign: 'end' }}>
						<span>
							Selected:
							{' '}
							{messages.length}
						</span>
					</Col>
				</Row>
			</Card>
		</HidableBottomPanel>
	);
};

export default InboxPageBatchAction;
