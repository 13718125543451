import {
	Dropdown, Menu, Popconfirm,
} from 'antd';
import { getCampaignIcon } from '@copilot/common/utils/campaign';
import EllipsisParagraph from '@copilot/common/components/common/ellipsisText';
import { ComponentProps, FC, useState } from 'react';
import { CampaignType } from '@copilot/data/responses/interface';

type Campaign = {
	id: string;
	type: string;
	name: string;
};

type CampaignSelectorDropdownProps = {
	campaigns: Campaign[];
	onConfirm: (targetCampaignId: string, targetCampaignName: string) => void;
	onCancel: () => void;
};

const CampaignSelectorDropdown:FC<CampaignSelectorDropdownProps> = (props) => {
	const { campaigns, onConfirm, onCancel } = props;
	return (
		<Menu>
			{
				campaigns
					.map((campaign) => (
						<Menu.Item key={campaign.id}>
							<Popconfirm
								title="Are you sure you want to move this prospect to the selected campaign?"
								okText="Confirm"
								onConfirm={() => onConfirm(campaign.id, campaign.name)}
								onCancel={onCancel}
								cancelText="Cancel"
								overlayStyle={{ width: '300px' }}
								placement="right"
							>
								<a style={{ display: 'flex', alignItems: 'center' }}>
									{getCampaignIcon(campaign.type as CampaignType)}
									<EllipsisParagraph text={campaign.name} />
								</a>
							</Popconfirm>
						</Menu.Item>
					))
			}
		</Menu>
	);
};

type CampaignSelectorProps = {
	campaigns: Campaign[];
	onSelect?: (targetCampaignId: string, targetCampaignName: string) => void;
	onDropdownVisible?: () => void;
} & Omit<ComponentProps<typeof Dropdown>, 'overlay'>;

/**
 * TODO: Update this to use content projection
 * [Presentational] Select a campaign from campaigns
 * @param {Campaign[]} campaigns the campaigns to display in the selector
 * @param {function} onSelect called when an option is selected
 * @param {function} onDropdownVisible called when the dropdown is visible
 */
const CampaignSelector: FC<CampaignSelectorProps> = (props) => {
	const {
		campaigns, onSelect, onDropdownVisible, children, ...rest
	} = props;
	const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
	const handleConfirm = (targetCampaignId: string, targetCampaignName: string) => {
		onSelect?.(targetCampaignId, targetCampaignName);
		setDropdownVisible(false);
	};

	const onVisibleChange = (isVisible: boolean) => {
		setDropdownVisible(isVisible);
		if (isVisible) onDropdownVisible?.();
	};

	return (
		<Dropdown
			{...rest}
			open={dropdownVisible}
			onVisibleChange={onVisibleChange}
			trigger={['click']}
			overlay={
				() => (
					<CampaignSelectorDropdown
						campaigns={campaigns}
						onConfirm={handleConfirm}
						onCancel={() => setDropdownVisible(false)}
					/>
				)
			}
		>
			{children}
		</Dropdown>
	);
};

export default CampaignSelector;
