import React from 'react';
import { IProduct, ProductStatusEnum } from '@copilot/data/responses/models/billing';
import ProductCard from './productCard';
import {
	Alert, Button, Skeleton, Space, Tabs, Typography, Card,
} from 'antd';
import {
	BillingEditWizardSteps,
	PricingSummaryContext,
} from '../../pages/customerDetails/billing/const';
import { LoadingPane } from '@copilot/common/components/loadingPane/loadingPane';
import ItemSelectionCard from '@copilot/common/components/card/itemSelectionCard';
import { useCSCustomerBillingEditTracking } from '../../pages/customerDetails/billing/tracking';
import styled from 'styled-components';

const StyledGrid = styled.div`
	display: grid;
	gap: 24px;
    justify-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

const ButtonFooter = styled.div`
	margin-top: 32px;
`;

const { Title } = Typography;

/**
 * Produces a sort function (usable by Array.sort) that does comparison which product has a specified price
 * @param priceId the price to search for
 */
const sortByHavingPrice = (priceId: string) => (a: IProduct, b: IProduct) => (hasPrice(b, priceId) ? 1 : 0) - (hasPrice(a, priceId) ? 1 : 0);

/**
  * Checks if a product has the specified price
  * @param product the specified product to check
  * @param priceId the specified price to look up
  * @returns whether the specified product has the specified price id
  */
const hasPrice = (product: IProduct, priceId: string) => product.prices.find((c) => c.id === priceId) !== undefined;

interface NewProductStepProps {
	/**
	 * The selectable products to display on the new product step.
	 */
	products: IProduct[];
	/**
	 * The original pricing id to match against product.
	 */
	originalPricingId?: string;
	/**
	 * The initial select pricing id to match against product.
	 */
	initialPricingId?: string;
	/**
	 * True if the component is in loading state.
	 */
	isLoading: boolean;
	/**
	 * The original pricing summary context. **NOTE**:
	 *
	 * Details:
	 * If it is a old product, it will be displayed as a non-selectable current product.
	 * If it is a legacy product, it will selectable
	 */
	originalPricingSummaryContext?: PricingSummaryContext;
	/**
	 * Callback for when pricing is selected.
	 */
	onPricingSelected: (pricingId: string) => void;
	/**
	 * Emits when cancel is clicked.
	 */
	onCancelClicked?: () => void;
	/**
	 * Called to update the current step, populated by Wizard.Step.MainContent
	 */
	setCurrentStep?: (node: number, modifications?: { [k: string]: any }) => void;

	/* Call back function to fetch invoice preview; invoked when user clicks "continue" */

	onContinueClicked?: () => void;
}

/**
 * [Presentational] component for new product step in the ChangeSubscriptionWizard
 */
const NewProductStep: React.FC<NewProductStepProps> = ({
	products,
	originalPricingId,
	initialPricingId,
	onPricingSelected,
	onCancelClicked,
	setCurrentStep,
	isLoading,
	originalPricingSummaryContext,
	onContinueClicked,
}) => {
	const trackEvent = useCSCustomerBillingEditTracking('New Product Step', {
		wizardStep: 'Choose New Plan',
	});
	return (
		<>
			<Title level={2} style={{ fontSize: '24px' }}>
				Choose New Plan
			</Title>
			<Tabs defaultActiveKey="all_plans">
				<Tabs.TabPane tab="All Plans" key="all_plans">
					<Alert
						style={{ marginBottom: '16px' }}
						message="Discounts will be shown and applied during checkout."
						type="warning"
					/>
					<LoadingPane isLoading={isLoading}>
						<LoadingPane.LoadingSection>
							<StyledGrid>
								<Card><Skeleton active /></Card>
								<Card><Skeleton active /></Card>
							</StyledGrid>
						</LoadingPane.LoadingSection>
						<StyledGrid>
							{originalPricingSummaryContext && originalPricingSummaryContext.productStatus !== ProductStatusEnum.Available && (
								<ItemSelectionCard
									title={originalPricingSummaryContext.productName}
									primaryText={originalPricingSummaryContext.formattedAmount}
									secondaryText={originalPricingSummaryContext.pricingName}
									showRibbonAndBorder
									ribbonText="Current Plan"
									hideSelectButton={
										!(originalPricingSummaryContext.productStatus === ProductStatusEnum.Legacy && initialPricingId != originalPricingId)
									}
									selectedClicked={() => { originalPricingId && onPricingSelected(originalPricingId); }}
									colorScheme="blue"
								/>
							)}
							{originalPricingId && products.sort(sortByHavingPrice(originalPricingId)).map((product: IProduct) => (
								<ProductCard
									key={product.id}
									product={product}
									originalPricingId={originalPricingId}
									selectedPricingId={initialPricingId}
									selectedClicked={onPricingSelected}
								/>
							))}
						</StyledGrid>
						{originalPricingSummaryContext?.productStatus === ProductStatusEnum.Old && <Alert style={{ marginTop: '16px' }} type="warning" message="Customer is currently on an discontinued plan. Please select a new plan to proceed" />}
						<ButtonFooter>
							<Space
								style={{ display: 'flex', justifyContent: 'flex-end' }}
								size={12}
							>
								<Button
									onClick={() => {
										trackEvent({ buttonClicked: 'Cancel' });
										onCancelClicked?.();
									}}
								>
									Cancel
								</Button>
								<Button
									disabled={originalPricingSummaryContext?.productStatus === ProductStatusEnum.Old && initialPricingId === originalPricingId}
									onClick={() => {
										trackEvent({ buttonClicked: 'Continue' });
										setCurrentStep?.(BillingEditWizardSteps.Confirm);
										onContinueClicked?.();
									}}
									type="primary"
								>
									Continue
								</Button>
							</Space>
						</ButtonFooter>
					</LoadingPane>
				</Tabs.TabPane>
			</Tabs>
		</>
	);
};

export default NewProductStep;
