import { Layout } from 'antd';
import React from 'react';
import CustomerDetailsSidebar from './sidebar';
import CustomerDetailsHeader from './header';
import { useParams } from 'react-router';
import { getOrganizationMemberName } from '@copilot/common/utils/organizationMember';
import DrawerContainerManager from '@copilot/common/components/drawer';
import { useSelector } from 'react-redux';
import { orgMemberOneSelector } from '../data/selectors';
import ModalManager from '@copilot/common/components/modals/manager';

interface CustomerDetailsLayoutProps {
	/**
	 * True if the customer details sidebar should be included in the layout.
	 */
	includeSidebar?: boolean;

	/**
	 * Whether to show a confirmation when leaving
	 */
	confirmOnLeave?: boolean;

	/**
	 * Function to get the header title given the orgMemberMame.
	 */
	getHeaderTitle?: (orgMemberName: string) => string;
	/**
	 * Function to get the URL to navigate back to given the orgMemberId.
	 */
	getBackUrl?: (orgMemberId: string) => string;
}

interface CustomerDetailsRouterParams {
	orgMemberId: string;
}

/**
 * [Smart] component for Customer Details Layout
 */
const CustomerDetailsLayout: React.FC<CustomerDetailsLayoutProps> = ({
	confirmOnLeave = false,
	includeSidebar = false,
	getHeaderTitle = (memberName) => memberName,
	getBackUrl = (orgMemberId) => `/details/${orgMemberId}/billing`,
	children,
}) => {
	const { orgMemberId } = useParams<CustomerDetailsRouterParams>();
	const orgMember = useSelector(orgMemberOneSelector(orgMemberId));

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<CustomerDetailsHeader
				title={orgMember && getHeaderTitle(getOrganizationMemberName(orgMember))}
				url={getBackUrl(orgMemberId)}
				confirmOnLeave={confirmOnLeave}
			/>
			<Layout>
				{includeSidebar && (
					<Layout.Sider>
						<CustomerDetailsSidebar name={orgMember && getOrganizationMemberName(orgMember)} orgMemberId={orgMemberId} />
					</Layout.Sider>
				)}
				<Layout.Content style={{ padding: 24 }}>
					{children}
					<DrawerContainerManager />
					<ModalManager />
				</Layout.Content>
			</Layout>
		</Layout>
	);
};

export default CustomerDetailsLayout;
