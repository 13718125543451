import { Config } from '@copilot/common/config';

const BASE_STRIPE_DOMAIN = 'https://dashboard.stripe.com';

/**
 * Creates a link to the subscription page on stripe for the provided subscription id
 * If no id is provided, it will create a link for the subscriptions home page on stripe
 * @param {string | null} id Subscription id
 * @returns The link to the subscription page
 */
export const createStripeSubscriptionLink = (id?: string) => `${BASE_STRIPE_DOMAIN}/subscriptions${id === undefined ? '' : `/${id}`}`;

/**
 * Creates a link to the customer page on stripe for the provided customer
 * @param id Customer id
 * @returns The link to the customer page
 */
export const createStripeCustomerLink = (id: string) => `${BASE_STRIPE_DOMAIN}/customers/${id}`;

/**
 * Creates a link to the upcoming stripe invoice for the provided customer and subscription
 * @param customerId Id of the customer
 * @param subscriptionId Id of the subscription
 * @returns The link to the upcoming invoice
 */
export const createStripeUpcomingInvoiceLink = (customerId: string, subscriptionId: string) => `${BASE_STRIPE_DOMAIN}/customers/${customerId}/upcoming_invoice/${subscriptionId}`;

/**
 * Creates a link to the invoice on stripe
 * @param id Invoice id
 * @returns The link to the stripe invoice
 */
export const createStripeInvoiceLink = (id: string) => `${Config.stripeInvoiceURL}${id}`;
