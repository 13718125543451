import { getCampaignIcon } from '@copilot/common/utils/campaign';
import { CampaignType } from '@copilot/data/responses/interface';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { Button, Col, Row, Tag, Typography } from 'antd';
import DropdownReminderSelector from '@copilot/common/components/selector/reminder/dropdownSelector/dropdownReminderSelector';
import styled from 'styled-components';
import { InboxTableMessage, READ_ROW_CLASSNAME, UNREAD_ROW_CLASSNAME } from './types';
import { Moment } from 'moment';
import { DeclinedMessage } from '../../../../../copy/inMailCopy';
import { InMailStatusConstants } from '../../../../../constant/inMailConstant';
import isEmpty from 'lodash/isEmpty';

export const StyledContainer = styled.div`
	.${READ_ROW_CLASSNAME} {
		background: #f7f7f7;
	}

	.${UNREAD_ROW_CLASSNAME} {
		font-weight: bold;
	}
`;

const StyledCol = styled(Col)`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

/**
 * Determines the message to display based on the message record's contents
 * @param record
 */
function getMessage(record: InboxTableMessage) {
	if (record.inMail?.status === InMailStatusConstants.Declined && isEmpty(record.lastReceived.message))
		return <DeclinedMessage />;
	return record.lastReceived.message;
}

/**
 * Get renderer for the last received message with the tags
 * @param tags
 */
export function getMessageWithTagRenderer(tags: string[]) {
	return (_: unknown, record: InboxTableMessage) => (
		<>
			<div>
				<StyledCol span={24}>{ getMessage(record) }</StyledCol>
			</div>
			<Row>
				{record.tags.map((tag) => (
					<StyledCol>
						<Tag
							color={tags.includes(tag.name) ? 'processing' : 'default'}
							style={{ cursor: 'pointer' }}
						>
							{tag.name}
						</Tag>
					</StyledCol>
				))}
			</Row>
		</>
	);
}
/**
 * Get Campaign name with campaign type icon
 * @param _ not used
 * @param record
 */
export function getCampaignNameWithIcon(_: unknown, record: InboxTableMessage) {
	return (
		<div
			style={{
				maxWidth: '100%',
				overflow: 'hidden',
				columnGap: '6px',
				display: 'flex',
				alignItems: 'center',
			}}
			title={record.campaignName}
		>
			{getCampaignIcon(record.campaignType as CampaignType)}
			<Typography.Paragraph
				ellipsis={{ rows: 2 }}
				style={{
					maxWidth: '100%',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					marginBottom: 0,
				}}
			>
				{record.campaignName}
			</Typography.Paragraph>
		</div>
	);
}

/**
 * Format the Last Updated timestamp
 * @param timestamp timestamp to format
 */
export function getLastUpdatedTimestamp(_: unknown, record: InboxTableMessage) {
	return <>{new Date(record.lastReceived.timestamp).toLocaleDateString()}</>;
}

/**
 * Get the conversation's calender's action button renderer
 * @param setReminder callback for setting reminder
 * @param cancelReminder callback for cancelling reminder
 */
export function getCalendarActionButtonRenderer(
	onSetReminderClick: () => void,
	setReminder: (threadId: string, reminderDate: Moment, isNewReminder: boolean) => void,
	cancelReminder: (threadId: string) => void
) {
	return (_: unknown, record: InboxTableMessage) => (
		<div onClick={UtilityFunctions.stopPropogation}>
			<DropdownReminderSelector
				onSetReminderClick={onSetReminderClick}
				onUpdate={(date: Moment, isNewReminder: boolean) =>
					setReminder(record.id, date, isNewReminder)
				}
				onCancel={() => cancelReminder(record.id)}
				isReminderSet={record.isSnoozed ?? false}
				buttonType="default"
			/>
		</div>
	);
}

/**
 * Get the conversation's dismiss button renderer
 * @param _ Not used
 * @param record The inbox model
 */
export function getDismissButtonRenderer(onDismiss: (threads: { threadId: string }[]) => void) {
	return (_: unknown, record: InboxTableMessage) => (
		<div onClick={UtilityFunctions.stopPropogation}>
			<Button onClick={() => onDismiss([record])} style={{ textAlign: 'left' }}>
				Dismiss
			</Button>
		</div>
	);
}

/** Get Row Classname based on the isRead Flag*/
export function getInboxTableRowClassname(row: { isRead: boolean }) {
	return row.isRead ? READ_ROW_CLASSNAME : UNREAD_ROW_CLASSNAME;
}
