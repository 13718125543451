/**
 * The response for individual billing from GetBillingInfo.
 * Includes information for the Stripe subscription
 *
 * @see {@link https://cassia.atlassian.net/wiki/spaces/~196664931/pages/396886252/Individual+Billing+Visibility+Design+Docs#Model}
 */
export interface IBillingInfo {
	/**
	 * The upcoming invoice of the customer
	 */
	upcomingInvoice?: IInvoice;
	/**
	 * The past invoice(s) of the customer
	 */
	pastInvoices: IInvoice[];

	/**
	 * The current subscription of the customer
	 */
	subscription: ISubscription;

	/**
	 * The previous subscription of the customer
	 */
	previousSubscription?: ISubscription;

	/**
	 * The stripe customer id
	 */
	id: string;
}

/**
 * The Subscription details for the customer on stripe
 */
export interface ISubscription {
	/**
	 * The associated subscriptionId for the customer
	 */
	id: string;

	/**
	 * Date time of the trial end
	 */
	trialEnd?: string;
	/**
	 * Date time of when the account is to be canceled
	 */
	cancelAtDate?: string;
	/**
	 * Date time for the scheduled update on the Stripe Account
	 */
	scheduleEndDate?: string;

	/**
	 * If the subscription has ended, the date the subscription ended
	 */
	endedDate?: string;

	currentPeriodStartDate: string;
	currentPeriodEndDate: string;

	items: ISubscriptionItem[];
	discounts: IDiscount[];
	/**
	 * The status of this subscription
	 */
	status: SubscriptionStatus;
}

/**
 * Describes a product
 */
export interface IProductItem {
	id: string;
	currency: string;
	formattedAmount: string;
	amount: number;
	productName: string;
	productId: string;
	pricingId: string;
	price: IPrice;
}

/**
 * Describes an individual item on a customers Subscription
 */
export interface ISubscriptionItem extends IProductItem {
	productStatus: ProductStatusEnum;
}

/**
 * Describes whether the status of a product is Available (available for new and existing purchases),
 * Legacy (only available for purchases already subscribed to it),
 * or Old (no longer supported)
 */
export enum ProductStatusEnum {
	Available,
	Legacy,
	Old
}

/**
 * An individual invoice. This could be in the future or the past
 */
export interface IInvoice {
	/**
	 * Id of the invoice. Note that upcoming invoice id for Stripe will always be null.
	 */
	id: string;
	/**
	 * The last payment error code for this invoice
	 */
	paymentStatus?: PaymentErrorCode;
	status: InvoiceStatus;
	lines: IInvoiceLineItem[];
	discounts: IDiscount[];
	dueDate: string;
	currency: string;
	/**
	 * Date time of the start of the invoiced period
	 */
	periodStart?: string;
	/**
	 * Date time of the end of the invoiced period
	 */
	periodEnd?: string;
	proratedAmount: number;
	formattedProratedAmount: string;
	subtotal: number;
	formattedSubtotal: string;
	taxRate: number;
	formattedTaxRate: string;
	taxAmount: number;
	formattedTaxAmount: string;
	credit: number;
	formattedCredit: string;
	/**
	 * The subtotal + tax.
	 */
	total: number;
	formattedTotal: string;
	/**
	 * The total - credit.
	 */
	amountDue: number;
	formattedAmountDue: string;
	customerEmail: string;
	createdDate: string;
	/**
	 * The URL to the invoice PDF
	 */
	invoicePdf: string;
}

/**
 * An invoiced item on a bill. May be a product, add-on, etc.
 */
export interface IInvoiceLineItem extends IProductItem {
	description: string;
}

/**
 * The duration type of a discount item on an invoice
 *
 * @see {@link https://stripe.com/docs/api/coupons/object#coupon_object-duration}
 */
export enum DiscountItemDurationType {
	Once = 'once',
	Repeating = 'repeating',
	Forever = 'forever'
}

/**
 * The different status an invoice could be in
 *
 * @see {@link https://stripe.com/docs/invoicing/overview#workflow-overview}
 */
export enum InvoiceStatus {
	draft = 'draft',
	open = 'open',
	paid = 'paid',
	uncollectible = 'uncollectible',
	void = 'void'
}

/**
 * Different subscription status
 *
 * @see {@link https://stripe.com/docs/api/subscriptions/object#subscription_object-status}
 */
export enum SubscriptionStatus {
	active = 'active',
	past_due = 'past_due',
	unpaid = 'unpaid',
	canceled = 'canceled',
	incomplete = 'incomplete',
	incomplete_expired = 'incomplete_expired',
	trialing = 'trialing'
}

/**
 * If an invoice is open and the last outcome of a payment failed this
 * is the status of that failed transaction
 *
 * @see {@link https://stripe.com/docs/declines/codes}
 */
export enum PaymentErrorCode {
	authentication_required = 'authentication_required',
	approve_with_id = 'approve_with_id',
	call_issuer = 'call_issuer',
	card_not_supported = 'card_not_supported',
	card_velocity_exceeded = 'card_velocity_exceeded',
	currency_not_supported = 'currency_not_supported',
	do_not_honor = 'do_not_honor',
	do_not_try_again = 'do_not_try_again',
	duplicate_transaction = 'duplicate_transaction',
	expired_card = 'expired_card',
	fraudulent = 'fraudulent',
	generic_decline = 'generic_decline',
	incorrect_number = 'incorrect_number',
	incorrect_cvc = 'incorrect_cvc',
	incorrect_pin = 'incorrect_pin',
	incorrect_zip = 'incorrect_zip',
	insufficient_funds = 'insufficient_funds',
	invalid_account = 'invalid_account',
	invalid_amount = 'invalid_amount',
	invalid_cvc = 'invalid_cvc',
	invalid_expiry_month = 'invalid_expiry_month',
	invalid_expiry_year = 'invalid_expiry_year',
	invalid_number = 'invalid_number',
	invalid_pin = 'invalid_pin',
	issuer_not_available = 'issuer_not_available',
	lost_card = 'lost_card',
	merchant_blacklist = 'merchant_blacklist',
	new_account_information_available = 'new_account_information_available',
	no_action_taken = 'no_action_taken',
	not_permitted = 'not_permitted',
	offline_pin_required = 'offline_pin_required',
	online_or_offline_pin_required = 'online_or_offline_pin_required',
	pickup_card = 'pickup_card',
	pin_try_exceeded = 'pin_try_exceeded',
	processing_error = 'processing_error',
	reenter_transaction = 'reenter_transaction',
	restricted_card = 'restricted_card',
	revocation_of_all_authorizations = 'revocation_of_all_authorizations',
	revocation_of_authorization = 'revocation_of_authorization',
	security_violation = 'security_violation',
	service_not_allowed = 'service_not_allowed',
	stolen_card = 'stolen_card',
	stop_payment_order = 'stop_payment_order',
	testmode_decline = 'testmode_decline',
	transaction_not_allowed = 'transaction_not_allowed',
	try_again_later = 'try_again_later',
	withdrawal_count_limit_exceeded = 'withdrawal_count_limit_exceeded'
}

/**
 * A discount on an invoice
 */
export interface IDiscount {
	id: string;
	amountOff?: number;
	percentOff?: number;
	currency: string;
	duration: DiscountItemDurationType;
	name: string;
	/**
	 * When duration is repeating the duration
	 * in months will be specified
	 */
	durationInMonths?: number;
	formattedDiscount: string;
	end: string;
	start: string;
	couponId: string;
}

export interface ICoupon {
	id: string;
	amountOff?: number;
	percentOff?: number;
	currency: string;
	duration: DiscountItemDurationType;
	durationInMonths?: number;
	name: string;
	formattedDiscount: string;
}

export interface IProduct {
	prices: IPrice[];
	name: string;
	productTier: string;
	id: string;
}

export interface IPrice {
	id: string;
	name: string;
	amount: number;
	formattedAmount: string;
	interval: PricingInterval;
	intervalCount: number;
	formattedInterval: string;
	currency: string;
	/**
	 * Inactive prices can no longer be used in the future for new purchases
	 */
	isActive: boolean;
}

/**
 * @enum day, month, week, or year
 */
export enum PricingInterval {
	Day = 'day',
	Week = 'week',
	Monthly = 'month',
	Yearly = 'year'
}

/**
 * Mapping of Pricing Intervals to Days
 */
export const PricingDateMap = {
	[PricingInterval.Day]: 1,
	[PricingInterval.Week]: 7,
	[PricingInterval.Monthly]: 30,
	[PricingInterval.Yearly]: 365,
};
