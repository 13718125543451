import { PricingInterval, ProductStatusEnum } from '@copilot/data/responses/models/billing';

export enum BillingEditWizardSteps {
	Product,
	Confirm
}

/**
 * Pricing summary context to display plan & pricing summary.
 */
export interface PricingSummaryContext {
	productName: string;
	productId: string;
	// Pricing Information
	pricingName: string;
	pricingInterval: PricingInterval;
	pricingIntervalCount: number;
	formattedAmount: string;
	formattedInterval: string;
	amount: number; // amount for the pricing selected
	productStatus: ProductStatusEnum;
}
