import React from 'react';
import {
	Col, Row, Typography,
} from 'antd';
import { IProductItem } from '@copilot/data/responses/models/billing';

const { Title, Text } = Typography;

/**
 * Properties to display product items
 */
interface ProductItemTableProps {
	/**
	 * The list of items to display in the product item table.
	 */
	items: IProductItem[];
	/**
	 * True if the product table should show a disabled UI (with "N/A").
	 * @default false
	 */
	disabled?: boolean;
}

/**
 * A summary of product items. Can either be invoice or subscription items.
 *
 * @param {ProductItem[]} props.items The list of items to display in the product item table.
 * @param {boolean} [props.disabled=false]  True if the product table should show a disabled UI (with "N/A").\
 */
const ProductItemTable: React.FC<ProductItemTableProps> = ({ items, disabled = false }) => (
	<>
		<Title type={disabled ? 'secondary' : undefined} level={5}>Plan Information</Title>
		{!items.length || disabled ?
			<Text type="secondary">N/A</Text> :
			items.map((item: IProductItem) => (
				<Row data-testid="invoice-row" justify="space-between" key={item.id} wrap={false}>
					<Col flex="auto">{item.productName}</Col>
					<Col flex="none" className="text-right">{item.formattedAmount}</Col>
				</Row>
			)
			)}
	</>
);

export default ProductItemTable;
