import { FC } from 'react';
import { Button, Col, Row } from 'antd';
import DataFilter from '@copilot/common/components/tables/dataFilter';
import FilterList from '@copilot/common/components/filters/list';
import styled from 'styled-components';
import { InboxMessagesFiltersProps } from '@copilot/common/pages/inbox/ui/types';
import BooleanItem from '@copilot/common/components/filters/booleanItem';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import { filterThenMapElements } from '@copilot/common/utils/common/array';
import { IN_PROGRESS, NOT_INTERESTED, POTENTIALLY_INTERESTED } from '../../constant';
import { MessageSentiments } from '@copilot/common/utils/constant/sentiments';

/**
 * The styled column to host filter.
 */
const StyledFilterColumn = styled(Col)`
	margin-right: 7px;
	flex: 0 1 auto;
	display: flex;
	flex-direction: column;
	max-width: 240px;
`;

const cleverlySentiments: FilterDefinition[] = [
	new FilterDefinition({
		key: POTENTIALLY_INTERESTED,
		isVisible: false,
		label: MessageSentiments.potentiallyInterested,
	}),
	new FilterDefinition({
		key: NOT_INTERESTED,
		isVisible: false,
		label: MessageSentiments.notInterested,
	}),
	new FilterDefinition({
		key: IN_PROGRESS,
		isVisible: false,
		label: MessageSentiments.inProgress,
	}),
];

/**
 * Get whether or not the key is visible for a given sentiment
 * @param key
 * @param sentiment
 */
const getIsCleverlyFilterVisible = (key: string, selectedSentiments: string[]): boolean => {
	if (key == POTENTIALLY_INTERESTED) return selectedSentiments.includes(MessageSentiments.interested) || selectedSentiments.includes(MessageSentiments.maybe);
	return selectedSentiments.includes(key);
};

/**
 * Get whether or not the key is visible for a given sentiment
 * @param key
 * @param sentiment
 */
const getIsFilterVisible = (sentiments: FilterDefinition[], sentiment: string): boolean => {
	if (sentiment == MessageSentiments.interested || sentiment == MessageSentiments.maybe) return !!sentiments.find((s) => s.key == POTENTIALLY_INTERESTED)?.isVisible;
	return !!sentiments.find((s) => s.key == sentiment)?.isVisible;
};

/**
 * Translate sentiments filter to cleverly sentiments
 * @param sentiments
 */
const filterCleverlySentiment = (sentiments: FilterDefinition[]) => {
	const selectedSentiments = filterThenMapElements(sentiments, (sentiment) => sentiment.isVisible, (sentiment) => sentiment.key);
	return cleverlySentiments.map((sentiment) => ({ ...sentiment, isVisible: getIsCleverlyFilterVisible(sentiment.key, selectedSentiments) }));
};

/**
 * Translate sentiments filter from cleverly sentiment
 * @param onSentimentUpdate
 * @param sentiments
 */
const handleCleverlySentimentUpdate = (onSentimentUpdate: (sentiments: FilterDefinition[]) => void, sentiments: FilterDefinition[]) => (updatedSentiments: FilterDefinition[]) =>
	onSentimentUpdate(sentiments.map((sentiment) => new FilterDefinition({ ...sentiment, isVisible: getIsFilterVisible(updatedSentiments, sentiment.key) })));

/**
 * Presentation Component for Inbox Email View Filters
 * @param props
 */
const CleverlyEmailViewFilters: FC<InboxMessagesFiltersProps> = (props) => {
	const { filters, filterUpdateCallbacks } = props;
	const {
		tags, campaigns, newMessagesOnly, showSnoozedOnly, sentiment,
	} = filters;
	const {
		onCampaignFilterUpdate,
		onFilterReset,
		onTagFilterUpdate,
		onNewMessagesOnlyUpdate,
		onShowSnoozedOnlyUpdate,
		onSentimentUpdate,
	} = filterUpdateCallbacks;
	return (
		<>
			<Row align="middle" style={{ alignItems: 'baseline', padding: '15px 0' }}>
				<StyledFilterColumn>
					<DataFilter
						title="Campaigns"
						filters={campaigns}
						updateFilters={onCampaignFilterUpdate}
					/>
					<FilterList
						filters={campaigns}
						updateFilterSelector={onCampaignFilterUpdate}
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					<DataFilter
						title="Tags"
						filters={tags}
						updateFilters={onTagFilterUpdate}
					/>
					<FilterList
						filters={tags}
						updateFilterSelector={onTagFilterUpdate}
						isExcludable
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					<DataFilter
						title="Initial Sentiment"
						filters={filterCleverlySentiment(sentiment)}
						updateFilters={handleCleverlySentimentUpdate(onSentimentUpdate, sentiment)}
					/>
					<FilterList
						filters={filterCleverlySentiment(sentiment)}
						updateFilterSelector={handleCleverlySentimentUpdate(onSentimentUpdate, sentiment)}
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					<Button
						onClick={() => onShowSnoozedOnlyUpdate(!showSnoozedOnly)}
						type={showSnoozedOnly ? 'primary' : 'default'}
						ghost={showSnoozedOnly}
					>
						Reminder Set
					</Button>
					<BooleanItem
						label="Reminder Set"
						isVisible={showSnoozedOnly}
						onClose={() => onShowSnoozedOnlyUpdate(false)}
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					<Button
						onClick={() => onNewMessagesOnlyUpdate(!newMessagesOnly)}
						type={newMessagesOnly ? 'primary' : 'default'}
						ghost={newMessagesOnly}
					>
						Unread
					</Button>
					<BooleanItem
						label="Unread"
						isVisible={newMessagesOnly}
						onClose={() => onNewMessagesOnlyUpdate(false)}
					/>
				</StyledFilterColumn>
			</Row>
			<Button style={{ marginBottom: '7px' }} onClick={onFilterReset}>
				Reset Filters
			</Button>
		</>
	);
};
export default CleverlyEmailViewFilters;
