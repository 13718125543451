import { Menu, Skeleton } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledHeaderContent = styled.div`
    color: #FFFFFF;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    overflow: hidden;
`;

interface CustomerDetailsSidebarProps {
	/**
	 * Name of the customer to be displayed in the sidebar header
	 */
	name?: string;
	/**
	 * Organization member id for the route path
	 *
	 * @example {customer_details_path}/:orgMemberId
	 */
	orgMemberId: string
}

/**
 * [Presentational] Component for the customer details sidebar
 *
 * @param {string} props.name Name of the customer to be displayed in the sidebar header
 * @param {string} props.orgMemberId Organization member id for the route path
 */
const CustomerDetailsSidebar: React.FC<CustomerDetailsSidebarProps> = (props) => {
	const { name, orgMemberId } = props;

	const sidebarHeader = (
		<div style={{ padding: '24px' }}>
			<StyledHeaderContent>
				{name ?? <Skeleton title={false} active paragraph={{ rows: 2 }} />}
			</StyledHeaderContent>
		</div>
	);

	// todo: selectedKeys will need to be updated once more menu items get added
	return (
		<Menu theme="dark" mode="inline" selectedKeys={['billing']}>
			{sidebarHeader}
			<Menu.Item key="billing">
				Billing
				<Link to={`/details/${orgMemberId}/billing`} />
			</Menu.Item>
		</Menu>
	);
};

export default CustomerDetailsSidebar;
