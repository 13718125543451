import React, {
	useState, useCallback, useEffect, lazy, useMemo,
} from 'react';
import HeaderContentLayout from '@copilot/common/pages/layouts/headerContent';
import { QueryObject } from '@copilot/data/managers/base';
import { useFetch, useSearchParams } from '@copilot/common/hooks/common';
import { useSelector, useDispatch } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { AppActions } from '@copilot/common/store/actions/app';
import { DateRangeFilterEnum } from '@copilot/common/components/filters/dateRange';
import { Card, Pagination } from 'antd';

import { AppSelectors, TeamClientSelectors } from '@copilot/common/store/selectors';
import { TeamClientActions } from '@copilot/common/store/actions';
import { AdminManager } from '@copilot/data/managers/admin';
import { useCustomersFetcher } from '@copilot/cs/src/hooks/customers';
import { PaddedContent } from '../style';

import type { IQueryProperties } from '@copilot/common/model/filters';
import { DefaultTeamsQueryValues } from '../constant';
import TeamCustomerPageContext from './context';
import { ITenant, ITenantType, useTwoLevelsTenantDataQuery } from '@copilot/data/graphql/_generated';
import { isUndefined } from 'lodash';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import Title from '@copilot/common/typography/title';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';

const TeamCustomersPageHeader = lazy(() => import('./header'));
const TeamCustomerInfoItem = lazy(() => import('@copilot/cs/src/components/infoItems/teamCustomer'));

const TeamCustomersPage: React.FC<{}> = () => {
	const isTenantDashboardFeatureEnabled = useFeatureToggle(Features.TenantDashboardFeature);
	const [searchParams, setSearchParams] = useSearchParams<Partial<IQueryProperties>>(DefaultTeamsQueryValues);
	const adminMember = useSelector(OrganizationMemberSelectors.getAdminMember);
	const [period, setPeriod] = useState<DateRangeFilterEnum>(DateRangeFilterEnum.Last7);
	const dispatch = useDispatch();
	const isFetching = useSelector(AppSelectors.getIsFetchingDataState);
	useEffect(() => {
		if (adminMember?.id) {
			dispatch(AppActions.loadActiveUser(adminMember.id));
		}
	}, [adminMember?.id]);

	const clientGetter = useCallback(async (query?: QueryObject) => {
		const result = await AdminManager.getTeamClients(query);
		dispatch(TeamClientActions.resetAll());
		return result;
	}, []);

	const [clientsFetch, fetchClients] = useFetch(
		clientGetter,
		TeamClientActions.load,
		(r) => r.results.map((org) => ({ ...org, id: org.teamsCustomerId }))
	);

	const {
		pageSize,
		currentPage,
		onPageChange,
		onShowSizeChange,
		onReload,
	} = useCustomersFetcher(searchParams, fetchClients, 2000);

	const clients = useSelector(TeamClientSelectors.getClients);
	const clientTotal = clientsFetch?.data?.totalCount ?? 0;

	function buildTenantMap(parentMap: Map<string, string>, idMap: Map<string, ITenant>, tenant: ITenant) {
		if (!isUndefined(tenant)) {
			// only build ID to Node Tenant
			if (tenant.type === ITenantType.Node) {
				idMap.set(tenant.id, tenant);
			}
			if (!isUndefined(tenant.childTenants)) {
				tenant.childTenants.forEach((childTenant) => {
					if (!isUndefined(childTenant)) {
						parentMap.set(childTenant.id, tenant.id);
						buildTenantMap(parentMap, idMap, childTenant);
					}
				});
			}
		}
	}

	// getting tenant data
	const [ parentTenantMap, setParentTenantMap ] = useState<Map<string, string>>(new Map());
	const [ nodeTenantIdMap, setNodeTenantIdMap ] = useState<Map<string, ITenant>>(new Map());
	const { data: tenantData, loading: isLoadingTenant, error: tenantLoadingError } = useTwoLevelsTenantDataQuery({ 
		variables: { 
			tenantFilter: { 
				tenantTypes: [ ITenantType.Leaf, ITenantType.Node ],
			},
		},
		skip: !isTenantDashboardFeatureEnabled,
	});
	useEffect(() => {
		if (isLoadingTenant) return;
		if (!isUndefined(tenantLoadingError)) {
			return;
		}

		if (isUndefined(tenantData)) return;

		const childParentTenantMap = new Map<string, string>();
		const idMap = new Map<string, ITenant>();
		tenantData.admin_tenants
			.filter(t => !isUndefined(t))
			// @ts-expect-error expecting TS validation error because of missing
			// 'childTenants' element in nested structure
			.forEach(t => buildTenantMap(childParentTenantMap, idMap, t));
		
		setParentTenantMap(childParentTenantMap);
		setNodeTenantIdMap(idMap);

	}, [tenantData, tenantLoadingError]);

	const onParentTenantUpdate = (tenantId: string, parentTenantId: string) => {
		setParentTenantMap(map => new Map(map).set(tenantId, parentTenantId));
	};

	const header = useMemo(() => (
		<TeamCustomersPageHeader
			handleUpdate={setSearchParams}
			param={searchParams}
			handlePeriodUpdate={setPeriod}
			results={clientTotal}
			nodeTenantIdMap={isTenantDashboardFeatureEnabled ? nodeTenantIdMap : undefined}
		/>
	),
	[searchParams, clientTotal, nodeTenantIdMap, isTenantDashboardFeatureEnabled]);

	const content = useMemo(
		() => clients.map(
			(client) => <TeamCustomerInfoItem key={client.id} client={client} period={period}
				childParentTenantMap={isTenantDashboardFeatureEnabled ? parentTenantMap : undefined}
				onParentTenantUpdate={isTenantDashboardFeatureEnabled ? onParentTenantUpdate : undefined}
				nodeTenantIdMap={isTenantDashboardFeatureEnabled ? nodeTenantIdMap : undefined}/>
		), [clients, period, parentTenantMap, nodeTenantIdMap]
	);

	return (
		<HeaderContentLayout>
			<TeamCustomerPageContext.Provider value={{ onReload }}>
				<HeaderContentLayout.Header>
					<React.Suspense fallback="Loading...">
						{header}
					</React.Suspense>
				</HeaderContentLayout.Header>
				<HeaderContentLayout.Content>
					<PaddedContent>
						<React.Suspense fallback="Loading...">
							<LoadingIndicator isLoading={isLoadingTenant || (isFetching ?? false)}>
								{content.length ? content : (
									<Card style={{ marginBottom: '1em', textAlign: 'center' }}>
										<Title level={5}>No Result found</Title>
									</Card>
								)}
							</LoadingIndicator>
						</React.Suspense>
						<Pagination
							current={currentPage}
							pageSize={pageSize}
							total={clientTotal}
							showSizeChanger
							onShowSizeChange={onShowSizeChange}
							onChange={onPageChange}
						/>
					</PaddedContent>
				</HeaderContentLayout.Content>
			</TeamCustomerPageContext.Provider>
		</HeaderContentLayout>
	);
};

export default TeamCustomersPage;
